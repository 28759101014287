import React from 'react'

import './App.css'

import Projects from './Projects'

const App = () => (
  <div className="App">
    <header className="App-header">
      <h1 className="Main-title">Welcome</h1>
    </header>
    <Projects />
  </div>
)

export default App
