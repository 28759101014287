import React from 'react'

import './Tag.css'

const Tag = ({ icon, label }) => (
  <div className="Tag-container">
    <div className="Tag-icon-container">
      <img className="Tag-icon" src={icon} />
    </div>
    <p className="Tag-label">{label}</p>
  </div>
)

export default Tag
