import React from 'react'

import './Card.css'

import Tag from './Tag'

const Card = ({ item }) => (
  <a href={item.link}>
    <div className={`Card-container ${item.link ? 'Active' : 'Inactive'}`}>
      <h3 className="Card-title">{item.title}</h3>
      <p className="Card-desc">{item.desc}</p>
      <div className="Card-tags">
        {item.tags.map(t => (
          <Tag key={t.key} icon={t.icon} label={t.label} />
        ))}
      </div>
    </div>
  </a>
)

export default Card
