import React from 'react'

import './Projects.css'

import Card from './Card'

const data = [
  {
    key: 'twitter_like',
    title: 'Twitter like',
    desc: 'A simple twitter like based on react, redis and graphql',
    link: 'https://github.com/ieschalier/twitter-like-react',
    tags: [
      {
        key: 'react',
        label: 'React',
        icon: '/img/react.png',
      },
      {
        key: 'graphql',
        label: 'GraphQL',
        icon: '/img/graphql.svg',
      },
      {
        key: 'redis',
        label: 'Redis',
        icon: '/img/redis.png',
      },
    ],
  },
  {
    key: 'expo-sdk-32-demo',
    title: 'expo-sdk-32-demo',
    link: 'https://github.com/ieschalier/expo-sdk-32-demo',
    desc:
      'Simple demo of new background location update and geofencing api introduced in SDK 32',
    tags: [
      {
        key: 'react',
        label: 'RN',
        icon: '/img/react.png',
      },
    ],
  },
  {
    key: 'confidential',
    title: 'confidential',
    desc: 'Mobile app but actually secret 🤫',
    tags: [
      {
        key: 'react',
        label: 'RN',
        icon: '/img/react.png',
      },
      {
        key: 'graphql',
        label: 'GraphQL',
        icon: '/img/graphql.svg',
      },
    ],
  },
]

const Projects = () => (
  <div className="Projects-container">
    <div>
      <h2 className="Projects-title">Projects</h2>
    </div>
    <div className="Projects-cards">
      {data.map(i => (
        <Card key={i.key} item={i} />
      ))}
    </div>
  </div>
)

export default Projects
